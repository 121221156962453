<template>
  <v-chip v-bind="$attrs" :color="assetTag.color" :density="density" prepend-icon="$assetTag">
    <span class="text-high-emphasis">{{ assetTag.description }}</span>
  </v-chip>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    assetTag: Pick<AssetTag, "color" | "description">
    density?: "comfortable" | "compact" | "default"
  }>(),
  {
    density: "comfortable",
  },
)
const { assetTag, density } = toRefs(props)
</script>
